/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useMemo } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import classNames from 'class-names'
import { Avatar, AvatarSizes } from '@bitaccess/bitaccess-ui'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import GroupDropdown from '../GroupDropdown'
import { useSelectSelectedGroup } from '../../state/group/hooks'
import BitaccessLogoWhite from '../../assets/bitaccess-wheel.png'
import { useLogout } from '../../state/application/hooks'

const profile = ['Sign Out']

export default function Header() {
  const { user } = useAuth0()
  const logout = useLogout()
  const selectedGroup = useSelectSelectedGroup()
  const { isLoading, isAuthenticated } = useAuth0()

  const navigation: {
    route: string
    name: string
  }[] = useMemo(
    () => [
      // { route: '/dashboard', name: 'Dashboard' },
      // {
      //   route: `/wallets`,
      //   name: 'Wallets',
      // },
      // // {
      //   route: `/transactions/${selectedGroup}`,
      //   name: 'Transaction History',
      // },
      // {
      //   route: `/reports/${selectedGroup}`,
      //   name: 'Reports',
      // },
    ],
    [selectedGroup],
  )

  return (
    <div className="w-full">
      <Disclosure as="nav" className="bg-bitaccess-blue">
        {({ open }) => (
          <>
            <div className="max-w-full mx-auto px-4 sm:px-10">
              <div className="flex items-center justify-between h-14">
                <div className="flex items-center">
                  <div className="">
                    <NavLink to={'/'}>
                      <img
                        style={{ width: 32 }}
                        src={BitaccessLogoWhite}
                        alt="Bitaccess Logo"
                        className="pointer-events-none"
                      />
                    </NavLink>
                  </div>
                  {isAuthenticated && !isLoading && (
                    <>
                      <div
                        className="pl-3 ml-6 relative border-l border-gray-600"
                        style={{ top: 1 }}
                      >
                        <GroupDropdown
                          border={`border-0`}
                          textColor={`text-white`}
                        />
                      </div>
                      <div className="hidden absolute left-0 lg:flex items-center justify-center mx-auto w-full">
                        <div className="flex items-baseline space-x-4">
                          {navigation.map((item: any) => (
                            <Fragment key={item?.name}>
                              <NavLink
                                to={item?.route}
                                activeClassName={'activeHeader'}
                                className="text-white no-underline hover:text-opacity-100 text-opacity-80 px-3 py-2 text-sm font-medium"
                              >
                                {item?.name}
                              </NavLink>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="hidden lg:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <Menu as="div" className="ml-3 relative">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="max-w-xs hover:brightness-105 flex items-center text-sm focus:outline-none focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              {user?.name && (
                                <Avatar
                                  username={user?.name}
                                  size={AvatarSizes.MEDIUM}
                                />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            // @ts-expect-error dumb
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute border border-gray-100 right-0 mt-2 w-48 shadow-lg py-0 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() => {
                                      logout()
                                    }}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm cursor-pointer',
                                    )}
                                  >
                                    Sign out
                                  </div>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 text-gray-200 hover:text-white hover:bg-gray-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            {/* <div className="w-full bg-white shadow-sm justify-center items-center flex h-12">
              <div className="hidden lg:block">
                <div className="flex items-baseline space-x-4">
                  {navigation.map((item) => (
                    <Fragment key={item.name}>
                      <NavLink
                        to={item.route}
                        activeClassName={'text-gray-800'}
                        className="text-gray-700 hover:text-opacity-100 text-opacity-80 px-3 py-2 text-sm font-medium"
                      >
                        {item.name}
                      </NavLink>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div> */}
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item: any) => (
                  <Fragment key={item.name}>
                    <NavLink
                      to={item.route}
                      activeClassName={'activeHeader'}
                      className="text-white hover:text-opacity-100 text-opacity-80 px-3 py-2 text-sm font-medium"
                    >
                      {item.name}
                    </NavLink>
                  </Fragment>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="px-2 space-y-1">
                  {profile.map((item) => (
                    <a
                      key={item}
                      href="#"
                      className="block px-3 py-2 text-base font-medium text-white hover:bg-opacity-75"
                    >
                      {item}
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

import { SearchIcon } from '@heroicons/react/solid'
import { matchAndMoveToFrontOfArray } from '../../utils'
import { InputGroup } from "@bitaccess/bitaccess-ui"
import { useCallback, useEffect, useMemo, useState } from 'react'
import Fuse from 'fuse.js'

export default function DropdownSearch({
  records,
  searchKeys,
  onSelect,
  idSelector,
  valueSelector,
  ItemComponent,
  defaultValue,
  inputLabel,
  inputPlaceholder,
}: {
  records: any[]
  searchKeys: string[]
  onSelect: (value: any) => void
  idSelector: (o: any) => string
  valueSelector: (o: any) => string
  ItemComponent: (props: {
    selected: boolean
    item: any
  }) => React.ReactComponentElement<any>
  defaultValue: string
  inputLabel: string
  inputPlaceholder: string
}) {
  const [searchResults, setSearchResults] = useState<any[]>(records)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const options = {
    keys: searchKeys,
    includeScore: true,
    threshold: 0.2,
  }

  const fuse = useMemo(() => new Fuse(records, options), [records])
  const handleSearch = useCallback(
    (e: any) => {
      const value = e.target.value
      setSearchValue(value)
      const result = fuse.search(value)
      const items = result.length > 0 ? result.map((r) => r.item) : records
      setSearchResults(items)
    },
    [`${records}`, fuse],
  )

  useEffect(() => {
    setSearchResults(records)
  }, [`${records}`])

  const handleSelectGroup = useCallback(
    (groupName: string) => {
      onSelect(groupName)
      setSelectedValue(groupName)
      setTimeout(() => {
        handleSearch({ target: { value: '' } })
      }, 200)
    },
    [handleSearch],
  )

  return (
    <div className="bg-white">
      <div className="px-0 bg-gray-50 border-b py-2">
        <InputGroup
          id="dropdown-search"
          placeHolder={inputPlaceholder}
          onChange={handleSearch}
          label={inputLabel}
          onEnter={() => console.log('enter')}
          inputStyles="mt-2"
          value={searchValue}
          InputIcon={<SearchIcon />}
          autoFocus={true}
        />
      </div>
      <ul style={{ maxHeight: 200 }} className="overflow-y-scroll mb-0 pl-0">
        {matchAndMoveToFrontOfArray(
          searchResults,
          (e) => e?.name === selectedValue,
        ).map((item: any) => {
          const id = idSelector(item)
          const value = valueSelector(item)
          const selected = selectedValue === value
          return (
            <li key={id} onClick={() => handleSelectGroup(value)} value={value}>
              <ItemComponent item={item} selected={selected} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

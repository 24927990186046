import { Spinner } from "@bitaccess/bitaccess-ui"

export default function Loading() {
  return (
    <div className="h-full w-full">
      <div className="m-auto">
        <Spinner id="loading-general" />
      </div>
    </div>
  )
}

import tw from 'twin.macro'

export const DropdownWrapper = tw.div`
  relative
  inline-block
  text-left
`

export const StyledDefaultDropdown = tw.button`
  bg-gray-50 
  inline-flex 
  items-center 
  px-4 
  py-1.5 
  border 
  border-gray-300 
  shadow-sm 
  text-sm 
  font-medium 
  text-gray-700 
  hover:bg-gray-50 
  active:bg-gray-100 
  focus:outline-none
`

export const StyledTextDropdown = tw.button`
  inline-flex 
  items-center 
  py-1.5
  text-xl 
  font-medium 
  text-gray-800 
  focus:z-10 
  focus:outline-none 
`

export const TextArea = tw.span`
  text-gray-400
`

export const SelectedText = tw.span`
  ml-1
  text-gray-700
  tracking-wide
`

export const DropdownList = tw.div`
  z-20
  origin-top-left
  absolute
  mt-2 
  w-80
  shadow-lg
  bg-gray-50
  border
  border-gray-100
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
`

export const DropdownListContent = tw.ul`
  z-50
  origin-top-left 
  absolute 
  mt-2
  w-64 
  shadow-lg 
  bg-white 
  ring-1 
  ring-black 
  ring-opacity-5 
  focus:outline-none
`

export const CloseIconWrapper = tw.div`
  absolute 
  right-3 
  top-3 
  cursor-pointer
`

export const ScreenReader = tw.span`
  sr-only
`
export const StyledChevronDownIcon = tw.span`
  -mr-1 
  ml-2 
  h-5 
  w-5
`

export const StyledXIcon = tw.span`
  relative 
  bottom-0.5 
  h-4 
  w-4 
  text-gray-400
`

export const StyledDropdownIcon = tw.span`
  mr-3 
  text-gray-400
`

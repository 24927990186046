import { useCallback, useEffect, useRef } from 'react'
import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'
import {
  useUpdateUserSelectedGroup,
  useSelectSelectedGroup,
  useSelectGroupsArray,
  useIsLoadingGroups,
} from '../../state/group/hooks'
import { Dropdown, DropdownType } from '../Dropdown'
import { useGetUserWallets } from '../../state/wallet/hooks'
import { GroupAttributes } from '../../state/group/types'
import DropdownSearch from '../DropdownSearch'
import SelectedRow from '../SelectedRow'
import { useSelectNetwork } from '../../state/application/hooks'

export default function GroupDrowndown({
  border,
  textColor,
}: {
  border?: string
  textColor?: string
}) {
  const selectedGroup = useSelectSelectedGroup()
  const groups = useSelectGroupsArray()
  const getGroupsWallets = useGetUserWallets()
  const isLoading = useIsLoadingGroups()
  const updateUserSelectedGroup = useUpdateUserSelectedGroup()
  const dropdownRef = useRef<any>()
  const selectedNetwork = useSelectNetwork()
  const setShowDropdownList = (value: boolean) => dropdownRef.current(value)

  const handleDontHaveSelectedGroup = useCallback(() => {
    const firstGroup = groups[0]
    if (!selectedGroup && firstGroup) {
      updateUserSelectedGroup(firstGroup?.name)
    }
  }, [`${groups}`, selectedGroup])

  const handleGetGroupsWallets = useCallback(async () => {
    if (!selectedGroup) return
    await getGroupsWallets([selectedGroup])
    return
  }, [selectedGroup, selectedNetwork])

  useEffect(() => {
    handleDontHaveSelectedGroup()
  }, [])

  useEffect(() => {
    handleDontHaveSelectedGroup()
  }, [`${groups}`])

  useEffect(() => {
    handleGetGroupsWallets()
  }, [handleGetGroupsWallets])

  const handleOnSelectSearchResult = (value: string) => {
    updateUserSelectedGroup(value)
    setShowDropdownList(false)
  }

  const ItemComponent = ({
    item,
    selected,
  }: {
    item: any
    selected: boolean
  }) => {
    return (
      <Link className="no-underline" to={`/wallets/${item?.name}`}>
        <SelectedRow value={item?.name} selected={selected} />
      </Link>
    )
  }

  return (
    <div className="relative z-10">
      {groups.length > 1 ? (
        <Dropdown
          id="group-settings"
          dropdownType={DropdownType.TEXT}
          text={selectedGroup && !isLoading ? selectedGroup : 'Loading...'}
          extraStyles="text-white text-sm hover:bg-white opacity:100 hover:bg-opacity-10 px-3 transition-colors duration-300"
          ref={dropdownRef}
        >
          <DropdownSearch
            onSelect={handleOnSelectSearchResult}
            idSelector={(item: GroupAttributes) => item.id}
            valueSelector={(item: GroupAttributes) => item.name}
            records={groups}
            searchKeys={['name']}
            inputPlaceholder="Organization name..."
            inputLabel="Filter Organizations"
            defaultValue={selectedGroup}
            ItemComponent={ItemComponent}
          />
        </Dropdown>
      ) : (
        // groups.length === 0 ? (
        //   <Dropdown
        //     id="group-settings"
        //     dropdownType={DropdownType.TEXT}
        //     text="Personal"
        //     extraStyles="text-white text-sm hover:bg-white opacity:100 hover:bg-opacity-10 px-3 transition-colors duration-300"
        //     ref={dropdownRef}
        //   >
        //     <Menu>
        //       <div className="text-center">
        //         <Menu.Items static>
        //           <Menu.Item>
        //             <p className="text-sm font-medium py-2">No Organizations</p>
        //           </Menu.Item>
        //           <Menu.Item>
        //             <button
        //               type="submit"
        //               className={
        //                 'block w-full px-4 py-2 text-sm bg-yellow-600 text-white hover:bg-yellow-600/90 focus:outline-none'
        //               }
        //             >
        //               Create Group
        //             </button>
        //           </Menu.Item>
        //         </Menu.Items>
        //       </div>
        //     </Menu>
        //   </Dropdown>
        // ) :
        <span className="text-white inline-block text-sm px-3 py-1.5 font-medium">
          {selectedGroup}
        </span>
      )}
    </div>
  )
}

import { useCallback, useEffect } from 'react'
import { shallowEqual, useSelector, batch } from 'react-redux'
import {
  updateSelectedGroup,
  updateGroupWallets,
  clearWalletIds,
  addGroups,
} from './actions'
import { AppState, useThunkDispatch } from '../index'
import { fetchGroups } from '../../services/GringottsClient'
import { useGetAccessToken } from '../user/hooks'
import { isTimeDifferenceInMinutesGreaterThan } from '../../utils'
import { useHistory, useParams } from 'react-router'
import { GroupAttributes, GroupRedux } from './types'

const defaultArray: any[] = []

export function useGetUserGroups(): () => Promise<
  GroupAttributes[] | undefined
> {
  const getAccessToken = useGetAccessToken()
  const dispatch = useThunkDispatch()
  return useCallback(async () => {
    try {
      const accessToken = await getAccessToken()
      const groups = await fetchGroups(accessToken)
      const allGroups: GroupRedux = {}
      groups.forEach((group) => {
        allGroups[group.name] = group
      })
      await dispatch(addGroups(allGroups))
      return groups
    } catch (err) {
      console.log('error getting groups', err)
    }
  }, [dispatch, getAccessToken])
}

export function useAddWalletIdsToGroups(): (wallets: any[]) => void {
  const dispatch = useThunkDispatch()
  return useCallback(
    (wallets: any[] = []) => {
      batch(() => {
        wallets.map((wallet: any) => {
          clearWalletIds(wallet.groups)
          wallet.groups?.forEach((group: any) => {
            if (!group) return
            dispatch(updateGroupWallets({ walletId: wallet.id, group }))
          })
        })
      })
    },
    [dispatch],
  )
}

export function useClearWalletIds(): (wallets: string[]) => void {
  const dispatch = useThunkDispatch()
  return useCallback(
    (wallets: string[] = []) => {
      batch(() => {
        wallets.forEach((group: any) => {
          dispatch(clearWalletIds(group))
        })
      })
    },
    [dispatch],
  )
}

export function useSelectGroups(): GroupRedux {
  const groups = useSelector(
    (state: AppState) => state.group.groups,
    shallowEqual,
  )
  return groups
}

export function useSelectGroupsArray(): GroupAttributes[] {
  const groups = useSelectGroups()
  return Object.values(groups) || defaultArray
}

export function useUpdateUserSelectedGroup(): (group: string) => void {
  const dispatch = useThunkDispatch()
  return useCallback(
    (group) => {
      if (!group) return
      dispatch(updateSelectedGroup(group))
    },
    [dispatch],
  )
}

export function useSelectSelectedGroup(): string {
  const selectedGroup = useSelector(
    (state: AppState) => state.group.selectedGroup,
  )
  return selectedGroup
}

export function useGetSelectedGroupWalletIds(): string[] {
  const groups = useSelectGroups()
  const selectedGroup = useSelectSelectedGroup()
  return selectedGroup ? groups[selectedGroup]?.walletIds || [] : []
}

export function useSelectGroupIds(): string[] | null {
  const groupIds = useSelector((state: AppState) => state.group.groups)
  return Object.keys(groupIds)
}

export function useIsLoadingGroups(): boolean {
  const isLoading = useSelector((state: AppState) => state.group.isLoading)
  return isLoading
}

export function useSelectGroupsLastUpdated(): number | null {
  const lastUpdated = useSelector((state: AppState) => state.group.lastUpdated)
  return lastUpdated
}

export function useShouldUpdateGroups(): (minutes: number) => boolean {
  const lastUpdated = useSelectGroupsLastUpdated()
  return useCallback(
    (minutes: number) => {
      if (!lastUpdated) return true
      return isTimeDifferenceInMinutesGreaterThan(lastUpdated, minutes)
    },
    [lastUpdated],
  )
}

import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import {
  updateWallet,
  addWallets,
  setLoadingWallets,
  setHideWalletBalances,
} from './actions'
import { WalletRedux } from './types'

export interface WalletState {
  wallets: WalletRedux
  isLoading: boolean
  hideWalletBalance: boolean
}

const initialState: WalletState = {
  wallets: {},
  isLoading: false,
  hideWalletBalance: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addWallets, (state, action) => {
      const wallets = action.payload
      const newState = set(state, 'wallets', wallets)
      return newState
    })
    .addCase(updateWallet, (state, action) => {
      const wallet = action.payload
      const walletId = wallet.id
      const newState = set(
        state,
        `wallets.${walletId}`,
        { ...state.wallets[walletId], ...wallet },
        { safe: true },
      )
      return newState
    })
    .addCase(setLoadingWallets, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    })
    .addCase(setHideWalletBalances, (state, action) => {
      const newState = set(state, 'hideWalletBalance', action.payload)
      return newState
    }),
)

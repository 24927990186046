import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import NewWalletForm from '../../components/NewWalletForm'
import PageLayout from '../../components/PageLayout'
import NewWalletSteps from '../../components/NewWalletSteps'

const NewWallet: FunctionComponent = () => {
  return (
    <div className="w-full min-h-full">
      <PageLayout pageTitle={``}>
        <div className="space-y-4">
          <div>
            <NewWalletSteps />
          </div>
          <div>
            <NewWalletForm />
          </div>
          <div>
            <div className="flex justify-end space-x-6 pt-10">
              <Link to={`/wallets`}>
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                  Cancel
                </button>
              </Link>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yelloe-600/90 focus:outline-none"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export default NewWallet

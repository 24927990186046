import { FunctionComponent } from 'react'

const NewWalletForm: FunctionComponent = () => {
  return (
    <div className="w-full">
      <form className="space-y-8">
        <div className="flex space-y-8 items-center justify-center">
          <div className="pt-8 w-full lg:w-[40%]">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Wallet Details
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Enter details for your new wallet
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Wallet Label
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Wallet Group
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>Personal</option>
                  </select>
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Wallet Network
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>Bitcoin</option>
                    <option>Ethereum</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewWalletForm

/* eslint-disable react/display-name */
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import HideBalanceSwtich from '../../components/HideBalanceSwitch'
import Table from '../../components/Table'
import { Button } from '@bitaccess/bitaccess-ui'
import { useSelectIsLoadingBalances } from '../../state/balance/hooks'
import { AccountingBalance } from '../../state/balance/types'
import {
  useGetUserWallets,
  useSelectHideWalletBalance,
  useSelectWalletByIdCallback,
} from '../../state/wallet/hooks'
import { useSelectSelectedGroup } from '../../state/group/hooks'
import WalletLabel from '../WalletLabel'
import PermissionRestricted from '../PermissionRestricted'
import { useSelectAccountingFilters } from '../../state/transaction/hooks'
import qs from 'query-string'

export default function WalletsTable({
  balances,
}: {
  balances: AccountingBalance[]
}) {
  const isLoading = useSelectIsLoadingBalances()
  const getWalletById = useSelectWalletByIdCallback()
  const hideWalletBalance = useSelectHideWalletBalance()
  const selectedGroup = useSelectSelectedGroup()
  const accountingFilters = useSelectAccountingFilters()

  const handleCreateTableRows: any = useMemo((): any => {
    return (
      balances &&
      balances?.map((balance) => {
        const wallet = getWalletById(balance?.walletId)
        return {
          column_1: wallet,
          column_2: {
            assetSymbol: balance.asset?.symbol,
            balance: balance?.totalBalance,
            hideWalletBalance,
          },
          column_3: {
            assetSymbol: balance?.asset?.symbol,
            pendingReconciliationBalance: balance?.pendingReconciliationBalance,
            hideWalletBalance,
          },
          column_5: balance,
        }
      })
    )
  }, [balances, getWalletById, hideWalletBalance])

  return (
    <Table
      id="balance-table"
      tableHeader={<HideBalanceSwtich />}
      columns={[
        {
          Header: 'Wallet',
          minWidth: 280,
          accessor: (d: any) =>
            `${d.column_1?.label} ${d.column_1?.asset?.symbol} ${d.column_1?.asset?.name}`,
          Cell: (e: any) => {
            const WalletLabelComp = (
              <WalletLabel
                labelClassName="text-base text-gray-600 hover:text-yellow-900"
                wallet={e.cell.row.original?.column_1}
                truncateLength={40}
                truncate
              />
            )
            return (
              <PermissionRestricted
                fallback={WalletLabelComp}
                perms={['view:wallet']}
              >
                <Link
                  to={{
                    pathname: `/wallets/${selectedGroup}/${e.cell.row.original?.column_1?.id}`,
                    search: qs.stringify(accountingFilters),
                  }}
                  className="no-underline select-text hover:text-yellow-900"
                >
                  {WalletLabelComp}
                </Link>
              </PermissionRestricted>
            )
          },
        },
        {
          Header: 'Total Balance',
          accessor: (d: any) => d.column_2?.balance,
          maxWidth: 140,
          minWidth: 110,
          Cell: (e: any) => {
            const assetSymbol = e.cell.row.original?.column_2?.assetSymbol
            return (
              <div className="flex items-center">
                <div>
                  {hideWalletBalance
                    ? '********'
                    : `${e.cell.row.original?.column_2?.balance} ${assetSymbol}`}
                </div>
              </div>
            )
          },
        },
        {
          Header: 'Pending Balance',
          accessor: (d: any) => d.column_3?.pendingReconciliationBalance,
          maxWidth: 140,
          minWidth: 110,
          Cell: (e: any) => {
            const pendingBalance =
              e.cell.row.original?.column_3?.pendingReconciliationBalance
            const assetSymbol = e.cell.row.original?.column_3?.assetSymbol
            return (
              <div className="flex items-center">
                <div>
                  {hideWalletBalance
                    ? '********'
                    : `${pendingBalance} ${assetSymbol}`}
                </div>
              </div>
            )
          },
        },
        {
          Header: '',
          accessor: 'column_5',
          Cell: (e: any) => {
            const walletId = e.value.walletId
            return (
              <PermissionRestricted perms={['view:wallet']}>
                <div className="text-right mr-4 md:mr-2 z-1">
                  <Link
                    to={{
                      pathname: `/wallets/${selectedGroup}/${walletId}`,
                      search: qs.stringify(accountingFilters),
                    }}
                    className="no-underline"
                  >
                    <Button
                      className="w-32 justify-center md:w-36 text-gray-800 focus:ring-0"
                      text={`View wallet`}
                      type="custom"
                      id="receive-button"
                    />
                  </Link>
                </div>
              </PermissionRestricted>
            )
          },
        },
      ]}
      data={handleCreateTableRows}
      searchPlaceholder="Filter wallets..."
      isLoading={isLoading}
      pagination={false}
    />
  )
}

import { useCallback, useRef } from 'react'
import { useGetSupportedAssets } from '../asset/hooks'
import { setLoadingAppData, setLoadingText, updateNetwork } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../index'
import {
  useGetUserGroups,
  useSelectGroupIds,
  useSelectGroups,
  useSelectGroupsArray,
  useSelectSelectedGroup,
  useUpdateUserSelectedGroup,
} from '../group/hooks'
import { setLoadingBalances } from '../balance/actions'
import { setLoadingTransactions } from '../transaction/actions'
import { setLoadingWallets } from '../wallet/actions'
import { useAuth0 } from '@auth0/auth0-react'
import { clearAllLocalStorage } from '../../utils'
import { APP_DOMAIN } from '../../constants'
import { useHistory } from 'react-router'
import { useGetUserWallets } from '../wallet/hooks'
import { useGetWalletBalanceByGroup } from '../balance/hooks'

export function useGetAppData(): () => Promise<void> {
  const getSupportedAssets = useGetSupportedAssets()
  const getUserGroups = useGetUserGroups()
  const getUserWallets = useGetUserWallets()
  const getWalletBalanceByGroup = useGetWalletBalanceByGroup()
  const selectedGroup = useSelectSelectedGroup()
  const updateSelectedGroup = useUpdateUserSelectedGroup()
  const dispatch = useDispatch()
  return useCallback(async () => {
    dispatch(setLoadingText('Loading Assets...'))
    let currentSelectedGroup = selectedGroup
    dispatch(setLoadingBalances(false))
    dispatch(setLoadingTransactions(false))
    dispatch(setLoadingWallets(false))
    dispatch(setLoadingAppData(true))
    await getSupportedAssets()
    dispatch(setLoadingText('Loading Organizations...'))
    const groups = (await getUserGroups()) || []
    if (
      (!groups?.some((g: any) => g?.name == selectedGroup) || !selectedGroup) &&
      groups[0]
    ) {
      updateSelectedGroup(groups[0]?.name)
      currentSelectedGroup = groups[0]?.name
    }
    dispatch(setLoadingText('Loading Wallets...'))
    await getUserWallets([currentSelectedGroup])
    dispatch(setLoadingText('Loading Balances...'))
    await getWalletBalanceByGroup(currentSelectedGroup)
    dispatch(setLoadingText(''))
    dispatch(setLoadingAppData(false))
  }, [
    dispatch,
    getSupportedAssets,
    getUserGroups,
    getUserWallets,
    getWalletBalanceByGroup,
    selectedGroup,
    updateSelectedGroup,
  ])
}

export function useIsAppLoadingData(): any {
  const isLoading = useSelector<AppState, AppState['application']['isLoading']>(
    (state) => state.application.isLoading,
  )
  return isLoading
}

export function useSelectLoadingText(): any {
  const loadingText = useSelector<
    AppState,
    AppState['application']['loadingText']
  >((state) => state.application.loadingText)
  return loadingText
}

export function useUpdateNework(): (network: 'mainnet' | 'testnet') => any {
  const dispatch = useDispatch()
  return useCallback(
    (network: 'mainnet' | 'testnet') => {
      dispatch(updateNetwork(network))
    },
    [dispatch],
  )
}

export function useSelectNetwork(): any {
  const network = useSelector<AppState, AppState['application']['network']>(
    (state) => state.application.network,
  )
  return network
}

export function useLogout(): () => void {
  const { logout } = useAuth0()
  return useCallback(() => {
    logout({
      returnTo: `${APP_DOMAIN}/logout`,
    })
    clearAllLocalStorage()
  }, [logout])
}

export function useHandleSelectedGroupRoute(): (path: string) => void {
  const history = useHistory()
  const selectedGroup = useSelectSelectedGroup()
  const groups = useSelectGroupsArray()
  const finalSelectedGroup = useRef(selectedGroup)
  return useCallback(
    (path: string) => {
      if (!finalSelectedGroup.current) {
        finalSelectedGroup.current = groups[0].name
      }
      if (finalSelectedGroup.current) {
        history.replace({
          pathname: `${path}/${selectedGroup}`,
          search: history.location.search,
        })
      }
    },
    [groups, history, selectedGroup],
  )
}
